/*.exclude-advertisers-text {*/
/*  margin-bottom: 0.5em;*/
/*  font-size: 1.3rem !important;*/
/*  font-weight: 500 !important;*/
/*}*/


div.vs-react-select-creatable__control {
  height: unset !important;
}

.scrollable {
  max-height: 50vh;
  overflow: scroll;
  margin-right: -22px;
}

.vsx-alert-d {
  top: unset;
  bottom: 8vh;
}

/*.vsx-list-item {*/
/*  padding: 10px !important;*/
/*}*/

.vsx-react-select-value-container {
  line-height: 1em
}

.vsx-react-select-input.vsx-react-select-input input {
  height: 16px
}

.vsx-react-select-input.vsx-react-select-input input:focus {
  box-shadow: none
}

.vs-topnav-header {
  height: 64px;
}

.vsx-top-nav-layout-title {
  color: #fff;
  display: inline-block;
  font-size: 2rem;
  margin: 0;
}

.vsx-top-nav-layout-logo {
  height: 40px !important;
  width: 40px !important;
}

.vs-topnav-content {
  width: 100%;
  bottom: 0;
  height: calc(100% - 64px);
  margin-top: unset;
  position: fixed;
}

.vs-topnav {
  height: 100vh;
}

.vsx-react-select-menu > div > div {
  color: inherit;
}

.modal-variant .modal-content {
  text-align: left;
}

.generate-code-script {
  font-weight: 500;
  margin-bottom: 10px;
}

